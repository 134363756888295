import bg from "./assets/background-swatch.png"
import bodies from "./assets/bodies-swatch.png"
import arms from './assets/arms-swatch.png'
import head from './assets/head-swatch.png'
import face from './assets/face-swatch.png'
import headwear from './assets/headwear-swatch.png'
const body = ``
export default function initSpinner(container: HTMLDivElement) {
    const imgs = [bg, bodies, arms, head, face, headwear].map(src => {
        const img = document.createElement('img')
        img.src = src
        container.appendChild(img)
        return img
    })
    setInterval(() => {
        if (document.body.classList.contains('minting')) {
            imgs.forEach(img => {
                const count = img.width / img.height
                const i = Math.floor(Math.random() * count)
                img.style.transform = `translate(${img.height * -i}px, 0px)`
            })
        }
    }, 100)
}