import h from "./h"

export default function loadAttributes(json: any, el: HTMLDivElement) {
    console.log(json)

    const attrs = json.attributes
    const table = h('table')
    attrs.forEach(attr => {
        const {trait_type, value} = attr
        const tr = h('tr')
        const left = h('td', trait_type)
        left.className = 'label'
        const right = h('td', value)
        tr.appendChild(left)
        tr.appendChild(right)
        table.appendChild(tr)
    })
    el.innerHTML = ""
    el.appendChild(table)
    console.log(table)
}