import { ethers } from "ethers";
import h from "./h";
import { openseaLink } from "./opensea";

function makeCard(tokenURI: any, tokenId: number) {
  const b64 = tokenURI.split(",")[1];
  const json = JSON.parse(atob(b64));
  console.log(json);
  const svg = atob(json.image.split(",")[1]);
  const div = h('div')
  const a = h('a') as HTMLAnchorElement
  a.href = openseaLink(tokenId)
  a.innerHTML = svg
  div.appendChild(a)
  console.log("div", div)
  return div
}

export async function loadSingleTokenIntoCollection(tokenId: ethers.BigNumber, el: HTMLDivElement, contract: ethers.Contract, atEnd: boolean) {
  const info = await contract.tokenURI(tokenId);
  if (atEnd) {
  el.appendChild(makeCard(info, tokenId.toNumber()))
  } else {
    el.prepend(makeCard(info, tokenId.toNumber()))
  }
}
export default async function loadCollection(
  el: HTMLDivElement,
  address: string,
  contract: ethers.Contract
) {
  el.innerHTML = ""
  console.log("Balance call for ", address)
  const balance = await contract.balanceOf(address);
  if (balance > 0) {
      document.body.classList.add("has-collection")
  }
  console.log(balance);
  for (var i = balance - 1; i >= 0; i--) {
    const tokenId = await contract.tokenOfOwnerByIndex(address, i);
    loadSingleTokenIntoCollection(tokenId, el, contract, true)
  }
}
