declare let window: any;

import "./index.css";
import "./jackpot.css";
import { ethers } from "ethers";
// import initSpinner from "./spinner";
import initSpinner from "./slot";
import ContractInfo from "../solidity/artifacts/contracts/Blockheads.sol/Blockheads.json";
import loadAttributes from "./loadAttributes";
import loadCollection, {
  loadSingleTokenIntoCollection,
} from "./loadCollection";
import contractAddress from "./contractAddress";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "./web3";
// import Web3 from "web3"

const $ = (s) => document.querySelector(s);
const $$ = (s) => document.querySelectorAll(s);

initSpinner($("#minting-spinner"));
async function main() {
  try {
    const network = window.location.hostname === "testnet.blockheads.family" ? "rinkeby" : "mainnet"
    const endpoint = window.location.hostname === "testnet.blockheads.family" ? "https://rinkeby.infura.io/v3/0d5355d2f5764f35801138abefcc8e00" : "https://mainnet.infura.io/v3/0d5355d2f5764f35801138abefcc8e00"
    console.log("Network", network, "Contract", contractAddress, endpoint)
    const web3Modal = new Web3Modal({
      network: network,
      cacheProvider: false,
      providerOptions: {
        walletconnect: {
          display: {
            name: "Mobile",
          },
          package: WalletConnectProvider,
          options: {
            network: network,
            infuraId: "0d5355d2f5764f35801138abefcc8e00",
            rpcUrl: endpoint
          },
        },
      }, 
    });
    const web3Provider = await web3Modal.connect();
    const web3 = new Web3(web3Provider)
    const accounts = await web3.eth.getAccounts()
    const addr = accounts[0]
    console.log("Account", addr);
    const provider = new ethers.providers.Web3Provider(web3Provider);

    const contract = new ethers.Contract(
      contractAddress,
      ContractInfo.abi,
      provider
    );

      loadCollection(
        $("#collection"),
        addr,
        contract
      );

    // Load how many tokens are available from the smart contract
    let tokenToWatch: ethers.BigNumber;
    async function loadAvailable() {
      try {
        const total = await contract.totalAvailable();
        const current = await contract.currentlyAvailable();
        const mintCost = await contract.mintCost();
        tokenToWatch = await contract.nextTokenId();
        console.log("Watching for token", tokenToWatch.toNumber());
        const etherCost = ethers.utils.formatEther(mintCost);
        if (tokenToWatch.gt(current)) {
          $("#mint").textContent = "Genesis Sold Out";
          $("#mint").disabled = true;
          $("#mint-pack-group").style.display = "none";
        } else {
          $("#mint").disabled = false;
          $("#mint").textContent = `Mint 1 for ${etherCost}Ξ`;
          $("#mint-pack-group").style.display = "block";
        }
      } catch (e) {
        console.error(e)
        $("#mint").textContent =
          "Please load with MetaMask or the Coinbase Wallet in-app browser";
      }
    }
    loadAvailable();

    async function stopAnimation() {
      document.body.classList.remove("minting");
    }
    
    async function loadToken(tokenId: ethers.BigNumber) {
      const tokenURI = await contract.tokenURI(tokenId);
      console.log({ tokenURI });
      const b64 = tokenURI.split(",")[1];
      const json = JSON.parse(atob(b64));
      console.log(json);
      $("#svg-holder").innerHTML = atob(json.image.split(",")[1]);
      $("#opensea").style.display = "block";
      $(
        "#opensea"
      ).href = `https://opensea.io/assets/${contractAddress}/${tokenId.toNumber()}`;
      $("#name").textContent = json.name;
      loadAttributes(json, $("#attributes"));
      stopAnimation();
      document.body.classList.add("loaded");
      document.body.classList.add("has-collection");
    }

    const mintButton = $("#mint") as HTMLButtonElement;
    async function mint(isPack: boolean) {
      mintButton.disabled = true;
      mintButton.textContent = "Confirming...";
      $("#mint-pack-group").style.display = "none";
      try {

        const contract = new ethers.Contract(
          contractAddress,
          ContractInfo.abi,
          provider.getSigner()
        );
        const mintCost = await contract.mintCost();
        if (isPack) {
          await contract.buy4get1free({ value: mintCost.mul(4) });
        } else {
          await contract.mint({ value: mintCost });
        }
        mintButton.textContent = "Minting...";
        document.body.classList.add("minting");
        $("#svg-holder").innerHTML = null;
        const loadedTokenIds = {};
        contract.on("Transfer", function (from, to, tokenId) {
          // console.log("Transfer", from, to, tokenId.toNumber())
          if (
            to.toLowerCase() == addr.toLowerCase() &&
            tokenToWatch.lte(tokenId)
          ) {
            mintButton.disabled = false;
            mintButton.textContent = "Mint another";
            loadAvailable();
            if (loadedTokenIds[tokenId.toNumber()]) return;
            loadedTokenIds[tokenId.toNumber()] = true;
            loadToken(tokenId);
            loadSingleTokenIntoCollection(
              tokenId,
              $("#collection"),
              contract,
              false
            );
          } else {
            // console.log("Doesn't match", to, addr, tokenId, tokenToWatch);
          }
        });
      } catch (e) {
        stopAnimation();
        loadAvailable();
      }
    }
    mintButton.addEventListener("click", () => mint(false));
    $("#mint-pack").addEventListener("click", () => mint(true));
  } catch (e) {
    console.log(e, "USE METAMASK");
  }
}
main();
