
const contractAddress = {
    ["mint.blockheads.family"]: "0x28812EC6E46C3e5093DE25E954a026ff99a57D53", // mainnet
    ["testnet.blockheads.family"]: "0x5562738F84FE3CbB242D83159905379Eab180619", // rinkeby
    ["localhost"]: "0x0165878A594ca255338adfa4d48449f69242Eb8F", // hardhat
  }[window.location.hostname];

export function openseaLink(tokenId: number) {
    const domain = window.location.hostname === "testnet.blockheads.family" ? 'testnets.opensea.io' : 'opensea.io'
    return `https://${domain}/assets/${contractAddress}/${tokenId}`;
}